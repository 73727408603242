import React, { useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { CartContext } from "../Component/CartContext";
import "../css/shop.css";
import productData from "../Datas/productData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function Shop() {
  const { addToCart } = useContext(CartContext);
  const [buttonClickedStatus, setButtonClickedStatus] = useState({});

  const handleAddToCart = (productId) => {
    addToCart(productId, 1);
    setButtonClickedStatus((prevState) => ({
      ...prevState,
      [productId]: true,
    }));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>
              GOODS&nbsp;
              <p style={{ fontSize: "20px", marginLeft: "-2%" }}> -グッズ-</p>
            </h1>
            <div>
              <img alt="" src="/head-foot/shop.webp" />
            </div>
            <h4 className="page_subheading_jp11 mincho">LADIES</h4>
            <div className="product-grid">
              {productData
                .filter((e) => e.id >= 1 && e.id <= 8)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <img alt="" src={brand.images[0]} />
                      <div>
                        <div className="item_sex">{brand.sex}</div>
                        <h5>{brand.name}</h5>
                        <div className="item_size">{brand.description}</div>
                        <div className="item_price">定価{brand.price}円</div>
                        <div>
                          <button
                            className="to-cart-btn"
                            onClick={() => handleAddToCart(brand.id)}
                          >
                            Add To Basket
                            {buttonClickedStatus[brand.id] && (
                              <FontAwesomeIcon icon={faCheck} />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <h4 className="page_subheading_jp11 mincho">MANS</h4>
            <div className="product-grid">
              {productData
                .filter((e) => e.id >= 9 && e.id <= 9)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <img alt="" src={brand.images[0]} />
                      <div>
                        <div className="item_sex" style={{ color: "#0e4cff" }}>
                          {brand.sex}
                        </div>
                        <h5>{brand.name}</h5>
                        <div className="item_size">{brand.description}</div>
                        <div className="item_price">定価{brand.price}円</div>
                        <div>
                          <button
                            className="to-cart-btn"
                            onClick={() => handleAddToCart(brand.id)}
                            style={{ backgroundColor: "rgb(14, 76, 255)" }}
                          >
                            Add To Basket
                            {buttonClickedStatus[brand.id] && (
                              <FontAwesomeIcon icon={faCheck} />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
