const productData = [
  {
    id: 1,
    name: "ライムイエロー",
    sex: "Ladies'",
    description: "Lime Yellow / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1001.jpg"],
  },
  {
    id: 2,
    name: "サクラ",
    sex: "Ladies'",
    description: "Sakura / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1002.jpg"],
  },
  {
    id: 3,
    name: "キャロット",
    sex: "Ladies'",
    description: "Carrot / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1003.jpg"],
  },
  {
    id: 4,
    name: "アクアブルー",
    sex: "Ladies'",
    description: "Aqua / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1004.jpg"],
  },
  {
    id: 5,
    name: "ティファニーブルー",
    sex: "Ladies'",
    description: "Tiffany Blue / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1005.jpg"],
  },
  {
    id: 6,
    name: "ミントグリーン",
    sex: "Ladies'",
    description: "Mint Green / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1006.jpg"],
  },
  {
    id: 7,
    name: "スプラッシュホワイト",
    sex: "Ladies'",
    description: "Splash White / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1007.jpg"],
  },
  {
    id: 8,
    name: "ピュアホワイト",
    sex: "Ladies'",
    description: "Pure White / XS(17cm)/SM(18cm〜19cm)/ML(20cm〜21cm)",
    price: "3800",
    images: ["/goods/1008.jpg"],
  },
  {
    id: 9,
    name: "ホワイトブルー",
    sex: "Men's",
    description: "White Blue / 21cm/22cm/23cm/24cm/25cm",
    price: "3800",
    images: ["/goods/1009.jpg"],
  },
  {
    id: 10,
    name: "Frozen Organic Seville Oranges",
    sex: "",
    description: [
      "Available in 2.5kg bags or 10kg boxes, our Frozen Organic Seville Oranges are sourced from famous traditional orchards renowned for growing the best bitter oranges.",
      "We recommend you use this fantastic orange for making Seville Orange Marmalade, or for using in a Seville Orange gin.",
      "Organic, unwaxed, and purchased directly from Seville, Spain during their peak season, this organic fruit has the optimum pectin levels for making excellent marmalade. They are quickly frozen and packed ready for you to enjoy whenever you please - no matter the season.",
    ],
    price: "£19.00",
    weight: ["2.5kg", "10kg"],
    images: ["/goods/10010.jpg", "/goods/100101.jpg"],
  },
  {
    id: 11,
    name: "Frozen Plum Halves",
    sex: "",
    description: [
      "Available in 2kg bags or 10kg boxes, these Plum Halves are a great choice for making jam, crumbles, cakes, or even poached with some mild spices. They look fantastic in an upside down plum cake!",
      "Our Plum Halves have been halved and destoned* then quickly frozen so you can use them at any time of the year for a fresh, quality taste.  ",
      "*NOTE: we cannot guarantee complete removal of stone and stone fragments.",
    ],
    price: "£14.00",
    weight: ["2kg", "10kg"],
    images: ["/goods/10011.jpg"],
  },
  {
    id: 12,
    name: "Frozen Diced Apricot",
    sex: "",
    description: [
      "Available in 10kg boxes, our Frozen Diced Apricots are a great addition to your freezer, with their sweet but tart flavour. They are perfect for creating a tasty apricot smoothie, making apricot jam, or in any other desserts.",
      "We've diced our apricots and frozen them quickly to ensure they are of the highest quality for you to enjoy at any time of the year.",
    ],
    price: "£40.00",
    weight: ["10kg"],
    images: ["/goods/10012.jpg", "/goods/100121.jpg"],
  },
  {
    id: 13,
    name: "Frozen Bramley Apple Slices",
    sex: "",
    description: [
      "Available in 2.5kg bags or 10kg boxes, these UK grown British Bramley Apple Slices are the perfect cooking apple due to their sourness and acidity. Use them to make a variety of recipes such as apple crumble, chutney or apple sauce to accompany your Sunday roast!",
      "They also pair perfectly with our organic blackberries to make a fruity crumble.",
      "The Bramley Apple Slices have been peeled, sliced and dipped in an antioxidant to keep them from browning. They are then quickly frozen so they can be easily used whenever you need them.",
    ],
    price: "£20.00",
    weight: ["2.5kg", "10kg"],
    images: ["/goods/10013.jpg"],
  },

  {
    id: 15,
    name: "Frozen Plum Halves",
    sex: "",
    description: [
      "Available in 2kg bags or 10kg boxes, these British Plum Halves are a great choice for making jam, crumbles, cakes, or even poached with some mild spices. They look fantastic in an upside down plum cake!",
      "Our UK grown Plum Halves have been halved and destoned* then quickly frozen so you can use them at any time of the year for a fresh, quality taste. ",
      "*NOTE: we cannot guarantee complete removal of stone and stone fragments.",
    ],
    price: "£21.00",
    weight: ["2.5kg", "10kg"],
    images: ["/goods/10015.jpg"],
  },
  {
    id: 16,
    name: "Frozen Redcurrants",
    sex: "",
    description: [
      "Available in either 3kg bags or 12kg boxes, our delicious redcurrants have a fruity but tart flavour. They are perfect for making redcurrant jelly, redcurrant sauce or other redcurrant recipes. They also make a delicious addition to a sauce or jus to enjoy with dark meats - lamb, pheasant, venison etc.",
      "Redcurrants are often underated as they have so many positive health benefits. They aid in helping to fight infections, help improve digestive health and are great for your hair and skin.",
      "Grown on our farm in Herefordshire and harvested in the height of the season, these redcurrants are Individually Quick Frozen, strigged and hand-selected before making their way to your doorstep.",
    ],
    price: "£21.00",
    weight: ["3kg", "14kg"],
    images: ["/goods/ccc.jpg"],
  },
  {
    id: 17,
    name: "Frozen Sweet Eating Fruit",
    sex: "",
    description: [
      "Our 2kg bag of British Sweet Eating Fruit is specifically chosen by hand - a mix of delicious Blueberries, sweet variety Blackberries, and our Premium Strawberries, to provide you with the perfect selection of sweet eating fruit.",
      "We recommend you use them to top cereal, porridge, yoghurt, ice-cream, or just for snacking, as they are ready to eat.",
      "We grow this mix of sweet fruit for their flavour on our Herefordshire farm, where they are picked at their optimum ripeness before being quickly frozen so your British fruit is high-quality all year round.",
    ],
    price: "£16.50",
    weight: ["2kg"],
    images: ["/goods/bbb.jpg"],
  },
  {
    id: 18,
    name: "Mango Chunks",
    sex: "",
    description: [
      "Available in 2.5kg bags or 10kg boxes, our Mango is an incredibly popular tropical fruit. Known for its fragrant sweetness, succulent texture and bright colour, it's the perfect fruit for a tasty smoothie, added into salads or paired with your favourite fish.",
      "Our frozen mango chunks are cut into easy to use pieces, so it's ready whenever you need it.",
      "Why not try our Super Quick Tropical Frozen Fruit Yoghurt.",
    ],
    price: "£20.00",
    weight: ["2.5kg", "10kg"],
    images: ["/goods/aaa.jpg"],
  },
];

export default productData;
