import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img alt="" src="/head-foot/main.jpg" />
          <div className="rslides-comment">
            <p className="welcome-strong">
              ゴルフで可能性を広げる
              <strong>GOLKANO.</strong>
            </p>
          </div>
        </div>
        <div className="hp-grid1">
          <div id="news_data">2024.08.16 </div>
          <br />
          <div id="news_text">【EVENT更新】申込開始！GOLKANO.OPEN 2024 </div>
        </div>
        <div id="intro">
          <div className="intro_contents">
            <div id="heading_en" className="mincho event_heading_en">
              INTRODUCTION
            </div>
            <h2 id="heading_jp" className="mincho event_heading_jp">
              ゴルフを通してあなたの可能性を広げます
            </h2>
            <h3 id="text" className="event_heading_text">
              <p>
                私たち株式会社GOLKANO.は、
                <br className="sp_display_off" />
                ゴルフを通して、関わる方々の可能性を広げるサポートをしていきます。
                <br />
                ゴルフの「ゴル」とカノウセイの「カノ」で、GOLKANO.（ゴルカノ）です。
              </p>
            </h3>
          </div>
          <div id="shape_1">
            <div
              id="bg-shape01"
              className="luxy-el bg-section"
              data-speed-y="-20"
            ></div>
          </div>
          <div id="shape_2">
            <div
              id="bg-shape02"
              className="luxy-el bg-section"
              data-speed-y="-17"
            ></div>
          </div>
        </div>
        <div id="horizon_textarea">
          <h4 id="horizon_text" className="mincho">
            INTRODUCTION
          </h4>
        </div>
        <div id="caddy">
          <div id="photo">
            <img alt="" src="/head-foot/main1.jpg" />
          </div>
          <div className="clearfix">
            <h3>CADDY</h3>
            <h4>あなたもゴルカノキャディになりませんか？</h4>
            <p>
              子どもとの時間を大切にしたい主婦の方や、高収入、高時給でスキルアップしたい副業希望の方など、ぜひゴルカノキャディとして活躍の場を広げてみませんか？
            </p>
            <p>
              たくさんの⼈と出会えるサイドジョブで、やりがいと充実感を実感することができます。
            </p>
          </div>
        </div>
        <section className="clearfix">
          <div id="event_bland" className="clearfix">
            <div id="shape_5" className="event_shape1">
              <img src="/head-foot/type_3_1.png" className="full" alt="" />
            </div>
            <div id="shape_6" className="event_shape2">
              <img src="/head-foot/type_3_2.png" className="full" alt="" />
            </div>
            <div id="event">
              <div
                id="section02"
                className="vh-element"
                data-props="padding-top"
                data-values="70"
              >
                <div
                  id="bg-section02"
                  className="luxy-el bg-section"
                  data-speed-y="-3"
                ></div>
              </div>
              <div id="text_area">
                <a href="https://golkano.co.jp/event/"></a>
                <h3 id="heading" className="mincho event_heading">
                  EVENT
                </h3>
                <div id="text" className="event_text">
                  イベント情報を掲載しています。
                </div>
                <div className="arrow event_arrow"></div>
              </div>
            </div>
            <div id="bland">
              <div
                id="section03"
                className="vh-element"
                data-props="padding-top"
                data-values="20"
              >
                <div
                  id="bg-section03"
                  className="luxy-el bg-section"
                  data-speed-y="-3"
                ></div>
              </div>
              <div id="text_area">
                <a href="https://golkano.co.jp/brand/"></a>
                <h3 id="heading" className="mincho bland_heading">
                  BRAND
                </h3>
                <div id="text" className="bland_text">
                  ゴルカノブランドについて。
                </div>
                <div className="arrow bland_arrow"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="whatsnew" className="clearfix">
          <div id="text_area">
            <h6 id="heading" className="whatsnew_heading mincho">
              What’s New
            </h6>
            <div id="text" className="whatsnew_text">
              What’snewを掲載をします。
            </div>
            <div id="learnmore" className="whatsnew_more">
              <a href="https://golkano.co.jp/news/">LEARN MORE</a>
            </div>
            <div className="arrow whatsnew_more"></div>
          </div>
          <ul>
            <li className="post whatsnew_post clearfix">
              <a href="https://golkano.co.jp/news/%e3%80%90event%e6%9b%b4%e6%96%b0%e3%80%91%e7%94%b3%e8%be%bc%e9%96%8b%e5%a7%8b%ef%bc%81golkano-open-2024"></a>
              <div className="photo">
                <a href="https://golkano.co.jp/news/%e3%80%90event%e6%9b%b4%e6%96%b0%e3%80%91%e7%94%b3%e8%be%bc%e9%96%8b%e5%a7%8b%ef%bc%81golkano-open-2024">
                  <img
                    width="900"
                    height="600"
                    src="https://golkano.co.jp/wp-content/uploads/2021/08/no_image_1-900x600.jpg"
                    data-lazy-type="image"
                    data-lazy-src="https://golkano.co.jp/wp-content/uploads/2021/08/no_image_1-900x600.jpg"
                    className="lazy full default-featured-img lazy-loaded"
                    alt=""
                    decoding="async"
                  />
                </a>
              </div>
              <div className="text_area">
                <div className="data">2024.08.16</div>
                <div className="text">
                  【EVENT更新】申込開始！GOLKANO.OPEN 2024
                </div>
              </div>
            </li>
            <li className="post whatsnew_post clearfix">
              <a href="https://golkano.co.jp/news/%e3%80%90blog%e6%9b%b4%e6%96%b0%e3%80%91%e4%bb%8a%e3%81%aa%e3%81%9c%e3%82%ad%e3%83%a3%e3%83%87%e3%82%a3%e3%81%aa%e3%81%ae%e3%81%8b%ef%bc%9f"></a>
              <div className="photo">
                <a href="https://golkano.co.jp/news/%e3%80%90blog%e6%9b%b4%e6%96%b0%e3%80%91%e4%bb%8a%e3%81%aa%e3%81%9c%e3%82%ad%e3%83%a3%e3%83%87%e3%82%a3%e3%81%aa%e3%81%ae%e3%81%8b%ef%bc%9f">
                  <img
                    width="900"
                    height="600"
                    src="https://golkano.co.jp/wp-content/uploads/2024/04/542A0BD8-AB8B-4A93-A6AA-AF31F3E3FA00-900x600.jpg"
                    data-lazy-type="image"
                    data-lazy-src="https://golkano.co.jp/wp-content/uploads/2024/04/542A0BD8-AB8B-4A93-A6AA-AF31F3E3FA00-900x600.jpg"
                    className="lazy full wp-post-image lazy-loaded"
                    alt=""
                    decoding="async"
                  />
                </a>
              </div>
              <div className="text_area">
                <div className="data">2024.04.09</div>
                <div className="text">【BLOG更新】今なぜキャディなのか？</div>
              </div>
            </li>
          </ul>
        </section>

        <ul id="index_recruit_bnr_landscape">
          <li>
            <a href="https://golkano.co.jp/caddy-homme/" target="_blank">
              <img
                src="https://golkano.co.jp/wp-content/themes/golkano/img/index/bnr_caddy_homme_pc.png"
                className="full fade change"
                alt=""
              />
            </a>
          </li>
          <li>
            <a href="https://golkano.co.jp/recruit/">
              <img
                src="https://golkano.co.jp/wp-content/themes/golkano/img/index/bnr_recruit_pc.png"
                className="full fade change"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
