import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>
              PRIVACY POLICY &nbsp;
              <p style={{ fontSize: "20px", marginLeft: "-2%" }}>
                -個人情報保護方針-
              </p>
            </h1>
            <div>
              <img alt="" src="/head-foot/mainvisual2.webp" />
            </div>

            <section className="page_contents_center_min fadeinup">
              <div className="page_subheading_en mincho">
                About privacy policy
              </div>
              <h2 className="page_subheading_jp mincho">
                個人情報保護方針について
              </h2>
            </section>

            <section className="contents_right_min fadeinright">
              <div className="contents_right_bg">
                <div className="contents_right_inner">
                  <table summary="privacy policy">
                    <tbody>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の定義について
                          </h3>
                          個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等によって特定の個人を識別できるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含みます。)をいいます。
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の取得・利用について
                          </h3>
                          <h4 className="privacy_subheading">
                            （1）個人情報の取得
                          </h4>
                          <p>
                            当社は、利用目的をできる限り特定して、利用目的を公表又は通知し、必要に応じて明示したうえで、利用目的に必要な範囲内に限定して、適切かつ公正な手段により、お客様の個人情報をお預かりいたします。なお、当社ウェブサイト、当社運営サイト及び当社運営のアプリケーションサービス等各種サービス(以下、総称し「当社サイト等」といいます)では、より円滑にサービスを提供するため、Cookieまたはウェブビーコン(以下「Cookie等」といいます。)を使用することがあります。Cookie等とは、お客様が当社サイト等にアクセスする際、お客様のパソコン等のウェブブラウザに一定の情報を格納し、お客様がサイトを訪れた日時等を記録することができるもので、お客様が当社サイト等を再度ご利用されることを容易にする技術です。Cookie等を読み込むことができるのは、これを設定したサイトのみです。※Cookieの使用について
                            当社では、当社のサービスをより便利にご利用いただくために、Cookieを使用しています。Cookieで収集したお客様の情報は、お客様の閲覧傾向を分析し.お客様により良いサービスを提供するため及び当社のサービス提供に必要な限りにおいてのみ使用し、その他の目的のために使用することは一切ありません。Cookieにより収集される情報はそれのみではお客様を特定するものではありませんが、お客様が当社によるCookieを用いた情報収集を希望しない場合は、インターネット閲覧ソフト(ブラウザ)の設定により、拒否することも可能です。ただし、当社によるCookieを用いた情報収集を拒否したお客様は、認証を必要とするウェブページにはアクセスすることができず、サービスを受けられない場合がありますのでご了承ください。
                          </p>
                          <h4 className="privacy_subheading">
                            （2）個人情報の利用
                          </h4>
                          <p>
                            当社は、当社の保有する個人情報を、当社又は当社と提携する者の電気通信サービスの提供業務に関し、以下の利用目的で利用します。お預かりした個人情報は、個人情報保護法その他の関係法規において認められる場合を除き、利用目的の範囲外で利用することはいたしません。なお、お客様が指定された他の方の宛先情報につきましては、そのご本人から個別の同意がない限り、1及び5の目的に限って利用します。
                          </p>
                          <br />
                          <ul>
                            <li>
                              [1]商品・サービスの提供、変更又は停止のためのご本人確認、商品・サービスの提供、商品・サービス提供に伴う対価の請求その他サービスの提供に関連する付随業務の遂行
                            </li>
                            <li>
                              [2]当提供する商品・サービスに関する情報の案内
                            </li>

                            <li>[3]新商品・新サービスの検討及び開発</li>
                            <li>
                              [4]サイト運営設備並びにこれらに附帯する設備の開発、運用及び保守
                            </li>
                            <li>[5]マーケティング調査その他の調査研究</li>
                            <p></p>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の安全管理について
                          </h3>
                          <p>
                            個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等によって特定の個人を識別できるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含みます。)をいいます。
                          </p>
                          <br />
                          <ul>
                            <li>
                              （1）当社は、お客様の個人情報の漏えい、滅失、き損を防ぐため、必要かつ適切な安全管理のための措置を講じます。
                            </li>
                            <li>
                              （2）当社は、個人情報への不正なアクセスを防ぐ社内システムを構築し、情報セキュリティについての規程を設けたうえで、従業員に対し、個人情報の取扱いについての周知徹底を図るべく、必要かつ適切な教育を実施します。
                              <br />
                            </li>
                            <li>
                              （3）当社は、お客様の個人情報を取扱う業務を他の会社へ委託する場合は、個人情報を適切に取り扱うと認められる者を委託先に選定したうえで、委託先に個人情報の適切な管理を義務付ける契約を交わし、委託先を適切に監督いたします。
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の第三者提供について
                          </h3>
                          <p>
                            （1）当社は、お客様よりお預かりした個人情報を、個人情報保護法その他の関係法規で認められる場合を除き、ご本人の同意なく第三者へ提供又は開示することはありません。
                          </p>

                          <p>
                            （2）当社運営サイトは、いくつかの外部サイトへのリンクを含みますが、個人情報を当社と共有し、又は当社から個人情報を提供するものではありません。リンク先サイトにて行われる個人情報の収集に関しては、リンク先サイトの個人情報取扱規定等を必ずご参照ください。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            法令遵守について
                          </h3>
                          <p>
                            当社は、個人情報保護法その他の関連法規を遵守いたします。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の開示、訂正等について
                          </h3>
                          <p>
                            当社の保有するお客様の個人情報に関して、お客様は個人情報保護法に基づき、開示、訂正等、利用の停止等を当社に申請いただくことができます。
                            <br />
                            申請手続につきましては、後記の窓口までお問い合わせ下さい。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の取扱いに関する苦情について
                          </h3>
                          <p>
                            当社の個人情報の取扱いに関するご本人からの苦情につきましては、後記の窓口までお願いいたします。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 className="page_subheading_jp">
                            個人情報の取扱いに関する受付窓口について
                          </h3>
                          <p>
                            [個人情報お問い合わせ担当窓口]
                            <br />
                            〒121-0073
                            <br />
                            東京都足立区六町4-4-35-1001
                            <br />
                            平日 10:00～18:00
                            <br />
                            TEL <Link to="tel:0358518681">03-5851-8681</Link>
                            <br />
                            定休日土日/祝日及び国民の祝日に関する法律(祝日法)上の休日/年末年始
                          </p>
                          <br />
                          <p>
                            当社は、本プライバシーポリシーを予告なしに変更する場合があります。この変更については、当社ウェブサイトに掲載して公表します。変更公表後の当社提供サービスの利用については、変更後のプライバシーポリシーが適用されるものとします。定期的にご確認ください。
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
