import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>
              ABOUT US &nbsp;
              <p style={{ fontSize: "20px", marginLeft: "-2%" }}> -企業情報-</p>
            </h1>
            <div>
              <img alt="" src="/head-foot/mainvisual.webp" />
            </div>
            <section className="page_contents_center fadeinup">
              <div className="page_subheading_en mincho">
                Corporate philosophy
              </div>
              <h2 className="philosophy_title mincho">企業理念</h2>
              <div className="philosophy_message mincho">
                「ゴルフで可能性を広げる」
              </div>
              <ul id="philosophy_sub">
                <li>
                  <h3 className="philosophy_sub_title">ビジョン</h3>
                  <div className="philosophy_sub_text">
                    ゴルフで可能性を広げるための
                    <br className="sp_display_off" />
                    〝機会”を提供する
                  </div>
                </li>
                <li>
                  <h3 className="philosophy_sub_title">ミッション</h3>
                  <div className="philosophy_sub_text">
                    ゴルフの魅力をあらゆる
                    <br className="sp_display_off" />
                    視点から追求し発信する
                  </div>
                </li>
                <li>
                  <h3 className="philosophy_sub_title">バリュー</h3>
                  <div className="philosophy_sub_text">
                    『笑顔』『謙虚』『品格』
                  </div>
                </li>
              </ul>
            </section>

            <section className="contents_right fadeinright">
              <div className="contents_right_heading">
                <h3 className="philosophy_title mincho">会社概要</h3>
              </div>
              <div className="contents_right_bg">
                <div id="inner">
                  <table summary="会社概要" className="table_default">
                    <tbody>
                      <tr>
                        <th>会社名</th>
                        <td>株式会社 GOLKANO.（ゴルカノ）</td>
                      </tr>
                      <tr>
                        <th>代表者</th>
                        <td>代表取締役　宮原　真里</td>
                      </tr>
                      <tr>
                        <th>設立</th>
                        <td>2016年8月</td>
                      </tr>
                      <tr>
                        <th>所在地</th>
                        <td>
                          <p>〒121-0073</p>
                          <p>東京都足立区六町4-4-35-1001</p>
                        </td>
                      </tr>
                      <tr>
                        <th>電話</th>
                        <td>
                          <Link to="tel:0358518681">03-5851-8681</Link>
                        </td>
                      </tr>
                      <tr>
                        <th>業務内容</th>
                        <td>
                          <ul className="ul_ul_li">
                            <li>キャディスタッフの育成、手配</li>
                            <li>ゴルフイベントの企画運営、スタッフ手配</li>
                            <li>オリジナルグローブの販売</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>取引先</th>
                        <td>
                          <ul className="ul_ul_li  ul_ul_li_last">
                            <li>
                              <Link to="https://www.odawarayumotocc.com/">
                                小田原湯本カントリークラブ
                              </Link>
                            </li>
                            <li>
                              <Link to="http://www.kiyokawa-cc.jp/">
                                清川カントリークラブ
                              </Link>
                            </li>
                            <li>
                              <Link to="https://www.taiheiyoclub.co.jp/course/sagami/">
                                太平洋クラブ相模コース
                              </Link>
                            </li>
                            <li>
                              <Link to="http://www.tsukuiko-gc.co.jp/">
                                津久井湖ゴルフ倶楽部
                              </Link>
                            </li>
                            <li>
                              <Link to="http://www.hakonekohan.com/">
                                箱根湖畔ゴルフコース
                              </Link>
                            </li>
                            <li>
                              <Link to="https://www.sengokugolf.jp">
                                富士屋ホテル仙石ゴルフコース
                              </Link>
                            </li>
                            <li>
                              <Link to="http://www.randomad.co.jp/">
                                株式会社ランダム・アド
                              </Link>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>提携先</th>
                        <td>
                          <ul className="ul_ul_li ul_ul_li_last">
                            <li>
                              <Link to="https://www.tokyo-jimushosagashi.com/">
                                東京事務所探しプラス
                                <br />
                                （リロン株式会社）
                              </Link>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="contents_bottom fadeinleft clearfix">
              <div id="greeting_textarea">
                <h3 className="page_subheading_jp mincho">代表あいさつ</h3>
                <p>
                  2021年4月、松山英樹プロがマスターズ優勝を果たし、日本人男子初めてのメジャー制覇。
                </p>
                <p>
                  同年6月、全米女子オープンでは、笹生優花プロと畑岡奈紗プロの日本人同士のプレーオフ(延長決定戦)が見られるなど、日本人選手の世界的な活躍が目覚ましい今日この頃。
                  また、プロの世界だけでなく、アマチュアゴルファーにとっては、コロナ以降、ゴルフは密にならないスポーツとして人気となり、平日にも関わらず予約でいっぱいのゴルフ場が増え、空前のゴルフブームが到来しています。
                  それに伴い、ありがたいことにキャディの需要がさらに高まっています。
                </p>
                <p>
                  ところで、「キャディ」の一般的なイメージといえば、圧倒的に多いのが「クラブを持ってきてくれる人」だと思います。
                </p>
                <p>
                  しかし、たとえばお客様から「7番アイアン持ってきて」と言われて、ただ運ぶだけではキャディとはいえません。単にクラブ運びをするのではなく、ピンまでの距離やハザードなど適切な情報をお伝えした上で、必要な番手（クラブ）を確認してお渡しします。
                  そして何より大事なのが、お客様が1日気持ちよくプレーできるように最大限サポートすること、これがキャディの仕事だと思っています。
                </p>
                <p>
                  弊社ゴルカノキャディは、全員ゴルファーでもあります。
                  ゴルフ未経験で入社しても、必ずゴルフを始めてもらっています。
                  それは自分自身がゴルフをしないと、プレーヤーであるお客様の気持ちを汲み取ることができないと考えているからです。
                </p>
                <p>
                  私たちGOLKANO.は、企業理念の元、『キャディ』という仕事に誇りを持ち、「笑顔、謙虚、品格」を忘れずに、何よりお客様に寄り添えるキャディを目指していきます。
                </p>
                <p>2021年8月吉日</p>
                <p>
                  株式会社GOLKANO.
                  <br />
                  代表取締役　宮原 真里
                </p>
              </div>
              <div id="greeting_photoarea">
                <div id="greeting_photo">
                  <img
                    src="https://golkano.co.jp/wp-content/themes/golkano/img/aboutus/president.jpg"
                    className="full"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
