import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="leftImg1">
            <img alt="" src="/head-foot/head1.svg" />
          </div>
          <div className="footMid">
            <nav className="navigation_foot">
              <ul className=" main-nav">
                <li className="menu-item">
                  <Link to="/" className="homeToA" onClick={toTop}>
                    HOME
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/shop" className="homeToA" onClick={toTop}>
                    GOODS
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/about" className="homeToA" onClick={toTop}>
                    ABOUT
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact" onClick={toTop}>
                    CONTACT
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/privacy" onClick={toTop}>
                    PRIVACYPOLICY
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
